import React, { useEffect } from "react";
import {useI18next} from 'gatsby-plugin-react-i18next';
import {includes} from "lodash";
import {navigate} from "gatsby";


const getRedirectLanguage = (languages, defaultLanguage) => {
    if (typeof navigator === `undefined`) {
        return defaultLanguage;
    }
    const lang = navigator && navigator.language && navigator.language.split("-")[0];

    if(includes(languages, lang)){
        return lang
    }
    else return defaultLanguage

};

const addSearchParamsToUrl = (url, searchParam) => {
    let result = url;

    if (searchParam && searchParam.startsWith('?')) {
        result = `${result}${searchParam}`;
    } else if (searchParam) {
        result = `${result}?${searchParam}`
    }
    return result;
}


export const redirect = (url, search = undefined) => {
        // const gatsby = require('gatsby-link');

        let redirect = url

        if (search) {
            redirect = addSearchParamsToUrl(redirect, search)
        }

        navigate(redirect, {replace: true});

}

const IndexPage = (props) => {
    const {languages, defaultLanguage, changeLanguage} = useI18next();
    const {isRoot, componentContext} = props.pageContext;

    useEffect(() => {
        let lngFromBrowser = getRedirectLanguage(languages, defaultLanguage);
        redirect(`/${lngFromBrowser}`);
    }, []);

    return null;
};

export default IndexPage;
